import Repository from '@/repositories/Repository'

export default {

    addService(itemType, itemId, locationId, groupSize, lessonDuration, lessonPrice, firstLessonFree) {
        var response = Repository.post('/teacher/addservice', {
            itemtype: itemType,
            itemid: itemId,
            locationid: locationId,
            groupsize: groupSize,
            lessonduration: lessonDuration,
            lessonprice: lessonPrice,
            firstlessonfree: firstLessonFree
        });
        return response;       
    },

    deleteService(teacherServiceId) {
        var response = Repository.post('/teacher/deleteservice', {
            teacherserviceid: teacherServiceId
        });
        return response;       
    },

    getAdvertisement() {
        var response = Repository.get('/teacher/getadvertisement', {
            params:{         
            } 
        });
        return response;
    },

    listServices() {
        var response = Repository.get('/teacher/listservices', {
            params:{         
            } 
        });
        return response;
    },

    listSubjectOptions() {
        var response = Repository.get('/teacher/listsubjectoptions', {
            params:{         
            } 
        });
        return response;
    },

    updateAdvertisement(advertisement) {
        var response = Repository.post('/teacher/updateadvertisement', {
            advertisement: advertisement
        });
        return response;       
    },

    updatePersonalDetails(displayName, phoneNumber) {
        var response = Repository.post('/teacher/updatepersonaldetails', {
            displayname: displayName,
            phonenumber: phoneNumber
        });
        return response;       
    },

};
